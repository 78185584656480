import React, { useState, useEffect } from "react"; 
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios"; 
import { Error }  from "../form/form";
import HelpdeskLayout  from "./helpdesk";
import moment from 'moment'
import 'moment/locale/cs';
import FileUpload  from "../fileUpload";  
import Finish  from "../helpdesk/finish";  

export default function HelpdeskDetail(props) {
  let { pozadavek } = useParams();
 
  const setModal = props.setModal;
  const [uploadId, setId] = useState();  
  const [submittedData] = useState({});
  const [loading, setLoading] = useState(false);  
  const [isOn, setIsOn] = useState(false)
  const history = useHistory();
  const building = props.building; 
  const user = props.user;  
  
  const { register, handleSubmit, errors, reset, formState: { isSubmitSuccessful }  } = useForm(); 
 
  const [item,setItem] = useState();

  const token = user.accessToken;
  const config = {
      headers: {
          "Authorization" : `Bearer ` + token
      }
  } 
  const inserted = moment(item?.vlozeno).format('D. M. YYYY');
  const done = moment(item?.last_update).format('D. M. YYYY');

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...submittedData });
    }
  }, [isSubmitSuccessful, submittedData, reset]);

  const onSubmit = async data => {  
    setLoading(true); 
    const response = await axios.post('https://system.stephanos.cz/api/helpdesk/comment/', data, config).then(res => { 
      setItem(res.data);
      reset(response);
      setLoading(false);
    }).catch(err => {       
      setLoading(false);
    })

  };

  function finish() {
    setModal(<><Finish {...props} item={item} /></>)
  }

  useEffect(() => { 
    pozadavek &&
      axios.get('https://system.stephanos.cz/api/helpdesk/detail/' + pozadavek + '/' + building?.unit?.units_id + '/',config) 
      .then(res => {
        if(res.data)
         setItem(res.data);
        else 
          history.push('/pozadavky/aktivni')
        
      })
        .catch(err => {
          console.log(err);
        });
      }
      // eslint-disable-next-line
  , [pozadavek, building]);
 
  useEffect(() => {
    if(!uploadId && isOn) {
      const id = 'upload_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); 
      setId(id);
    }  
  }, [uploadId, isOn]); 
  
    return(
        <>
          <HelpdeskLayout {...props}>
            
          {item ? (
            <>
                     <div className="w-full ">
                   <div className="grid grid-cols-2 border-b border-gray-200 pb-2">

                     <h3 className="text-lg font-medium md:text-2xl   ">
                       {item?.nazev}
                       </h3>  
                       {item.done !== '1' && item.vyrizuje === props.user.id &&
                       <div className="flex justify-end">
                          <button onClick={() => finish()} className="inline-flex items-center px-4 py-2 border text-white border-green-400 rounded-md shadow-sm text-sm font-medium hover:bg-green-600 focus:outline-none bg-green-500">
                            Vyřídit požadavek
                          </button>
                       </div>
                       
                       }
  
                   </div>
               
                       <div className="grid grid-cols-3 lg:grid-cols-7 text-sm w-full gap-4 py-4 border-b border-gray-200 items-center">
            <div className="">
                <span className={"font-medium " +  (item.done==="0" && "text-green-600") }># {item.number}</span> 
              </div>
              <div>
              <span className="flex items-start w-full justify-items-start">
  
                
                <span className="w-6 flex items-start">
  
                {item.private==='1' ? (
                  <svg className="w-4 mr-1 pt-1 inline text-red-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" title="viditelné pouze výboru">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                  ) : ("")}
                {item.private==='2' ? ( 
                  <svg className="w-4 mr-1 pt-1 inline text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" title="soukromý požadavek">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                </svg>
                ) : ("")}
                </span>
  
                {item.typ}
                </span>
                
              </div>
              
              <div className=" " >
                <span className="flex items-start w-full  ">
                <svg className="w-4 mr-1 pt-1 inline text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                {inserted}
                </span>
              </div>
              
              <div className="  ">
              <span >{item.vlozil}</span>
              </div>
              <div className="  ">
              <span >{item.unit}</span>
              </div> 
               
                <div className="col-span-2 justify-end" >
                  {item.done==='1' ? (
                    <>
                      <span className="flex items-start w-full justify-center">
                      <svg className="w-4 mr-1 pt-1 inline text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {done}
                      </span>
                    </>
                  ) : (
  
                  <div className="bg-gray-100 px-1 py-1 rounded-md flex items-start">
                  <svg  className="w-4 mr-2 pt-1 inline"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                    Vyřizuje {item.prirazeno}
  
                  </div>
  
                  )
                
                }
  
  
                </div>
                 
              </div>
  
                       
                      
<div className="flow-root py-3">
    <ul className=" divide-y divide-gray-200 ">
          <li >
                <div className="relative py-5">
                   
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">
                      {item?.img ? (

                        <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src={'https://system.stephanos.cz' + item.img } alt="" />
                      ) : (
                        <>
                          <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          
                          <svg className="h-7 w-7 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                          </svg>
                        </div>
                        </>
                      )}
 
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <span className="font-medium text-gray-900">
                            {item.vlozil}
                            </span>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          {moment(item.vlozeno).format('LLL')}
                        </p>
                        
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <div dangerouslySetInnerHTML={{__html: item.text}}>
                          
                        </div>
                        {item?.files && item?.files?.length > 0 && (
                              
                              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 my-3">
                            
        { item?.files.map((file, index) => { 
          return (
                <li key={index} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 relative">
                <div className="w-full flex items-center justify-between p-6 space-x-6">  
                
                  <div className="flex items-center space-x-2 overflow-hidden">
                  <div className="flex-shrink-0">
                  
                  </div>
                  <div className="flex-1 min-w-0  ">
                    {file.files_type === 'img' ? (
                      <img src={"https://system.stephanos.cz/" + file.files_filename} alt={file.files_name} className="cursor-pointer h-24" 
                      onClick={() => setModal(
                        <img src={"https://system.stephanos.cz/" + file.files_filename} alt={file.files_name} className="cursor-pointer"  />
                      )}
                      />

                    ) : (

                    <p className="text-sm font-medium text-gray-900 truncate">
                      {file.files_name}.{file.files_ext}
                    </p>
                    )
                    }
                    
                  </div>
                  <div> 
                  </div>
                </div>
                </div>
              </li>
                
          
        
            )
          })
        }
        </ul>
                            
                              
                                    
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>


        { item?.comments?.map((comment, index) => { 
              return (
              <li key={index}>
                <div className="relative py-5">
                   
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">
                      {comment?.img ? (

                        <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src={'https://system.stephanos.cz' + comment.img } alt="" />
                      ) : (
                        <>
                          <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          
                          <svg className="h-7 w-7 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                          </svg>
                        </div>
                        </>
                      )}
 
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <span className="font-medium text-gray-900">
                          {comment.vlozil ? (
                            comment.vlozil
                          ) : (
                            comment.nickname
                          )}
                            </span>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          {moment(comment.inserted).format('LLL')}
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <div dangerouslySetInnerHTML={{__html: comment.message}}>
                          
                        </div>

                        {comment?.files && comment?.files?.length > 0 && (
                              
                              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 my-3">
                            
        { comment?.files.map((file, index) => { 
          return (
                <li key={index} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 relative">
                <div className="w-full flex items-center justify-between p-6 space-x-6">  
                
                  <div className="flex items-center space-x-2 overflow-hidden">
                  <div className="flex-shrink-0">
                  
                  </div>
                  <div className="flex-1 min-w-0  ">
                    {file.files_type === 'img' ? (
                      <img src={"https://system.stephanos.cz/" + file.files_filename} alt={file.files_name} className="cursor-pointer h-24" 
                      onClick={() => setModal(
                        <img src={"https://system.stephanos.cz/" + file.files_filename} alt={file.files_name} className="cursor-pointer"  />
                      )}
                      />

                    ) : (

                    <p className="text-sm font-medium text-gray-900 truncate">
                      {file.files_name}.{file.files_ext}
                    </p>
                    )
                    }
                    
                  </div>
                  <div> 
                  </div>
                </div>
                </div>
              </li>
                
          
        
            )
          })
        }
        </ul>
                            
                              
                                    
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
                )
              })
            } 

            <li >
                <div className="relative py-5">
                   
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">
                      {user?.img ? (

                        <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src={ user.img } alt="" />
                      ) : (
                        <>
                          <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          
                          <svg className="h-7 w-7 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                          </svg>
                        </div>
                        </>
                      )}
 
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                            <form onSubmit={handleSubmit(onSubmit)} >
                            {item && 
                              <input type="hidden" ref={register()} name="item_id" defaultValue={item?.id} />
                            }
                            <input type="hidden" ref={register()} name="unit_id" defaultValue={item?.unit_id} />
                            <input type="hidden" ref={register()} name="number" defaultValue={item?.number} />
                              <div className="relative max-w-xl group">
                                <button type="button" onClick={() => isOn ? (setIsOn(false)) : (setIsOn(true))} 
                                className={"p-1  border rounded-full absolute top-2 right-2 focus:outline-none " + (isOn ? ("bg-green-500 text-white border-green-600 ") : ("bg-gray-200 text-gray-600 border-gray-400 "))} title="Přidat přílohu">
                                <svg className="w-5  " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                </svg>
                                </button>
                                <textarea ref={register({ required: true })} className="border-1 bg-gray-50 border-gray-300 flex-grow  h-24 px-3 py-1 pr-6 w-full rounded-lg focus:border-gray-300 focus:placeholder-gray-200 focus:ring-2 focus:outline-none  focus:ring-green-500"
                                name="message"   placeholder="Napište komentář"></textarea>
                                {errors.name && 
                                  <Error text="Komentář nelze odeslat prázdný" />
                                }
                                {isOn &&
                                <>
                                  <FileUpload uploadId={uploadId} setModal={setModal} />
                                  <input type="hidden" ref={register} name="uploadId" defaultValue={uploadId} />
                                </>
                                }
                              </div>
                              <div className="mt-2">
                                  {loading ? (
                                    
                                    <button disabled className="bg-gray-200 text-gray-800 font-bold py-2 px-10 w-full lg:w-auto rounded cursor-default">
                                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg>
                                      <span>Odesílání...</span></button>
                                  ) : (
                                    <>
                                    <button type="submit" className="bg-gray-700 text-white font-bold py-2 px-10 w-full lg:w-auto rounded hover:bg-gray-600"
                                      
                                    >Přidat komentář</button>
                                    
                                    </>
                                  )}


                              </div>

                          </form>
                      </div> 
                    </div>
                  </div>
                </div>
              </li>
      </ul>
  </div>
                       </div>
   
              <div className="pt-6 divide-y divide-gray-200"> 
                   
              </div> 

              </> 
              ) : (
                <>
                      <ul   className="flex flex-1 flex-wrap my-10 py-5">
                        <li  className="h-full w-full text-center flex flex-col justify-center items-center  "> 
                             <svg className="animate-spin -ml-1 mr-3  w-10 inline" fill="none" viewBox="0 0 24 24">
                               <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                               <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                             </svg> 
                             <span className="text-small text-gray-400">Načítání...</span>
                        </li>
                      </ul>
                    </>
              ) 
           }
 

                
            </HelpdeskLayout>
        </>
    )
}