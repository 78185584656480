import React from "react"; 
import { NavLink } from "react-router-dom"; 

export default function HelpdeskLayout(props) { 
  const building = props.building
  const user = props.user
  const helpdeskItems = props.helpdeskItems  
   
    return(
        <>
                <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div className="bg-white rounded-lg shadow overflow-hidden">
      
          <div className="divide-y divide-gray-200 lg:divide-y-0 lg:divide-x">
          

            <div className="divide-y divide-gray-200 lg:col-span-9"  >
            {building && user &&
            <>
              <nav className="bg-white shadow">
                <div className="  px-6 lg:px-8">
                  <div className="relative flex justify-between h-16">
                    <div className="flex-1 flex  items-stretch justify-between">
                      <div className="flex space-x-4 lg:space-x-8">
                        
                        {/* <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
                        <NavLink exact to={'/'} activeClassName="border-green-500 " className="border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                          Nový 
                          <span className="hidden lg:block lg:ml-1">požadavek</span>
                        </NavLink>
                        {(building.unit.groups_alias === 'spravce' || building.unit.groups_alias === 'admin') &&
                        <NavLink to={'/pozadavky/moje'} activeClassName="border-green-500 " className="border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                          Moje
                          <span className="ml-2 inline-block py-0.5 px-3 text-xs rounded-full bg-gray-200 group-hover:bg-gray-400">
                            {helpdeskItems?.my?.length}
                            </span>
                        </NavLink>
                        }
                        <NavLink to={'/pozadavky/aktivni'} activeClassName="border-green-500 " className="border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                          Aktivní
                          <span className="ml-2 inline-block py-0.5 px-3 text-xs rounded-full bg-gray-200 group-hover:bg-gray-400">
                            {helpdeskItems?.todo?.length}
                            </span>
                        </NavLink>
                        <NavLink exact to={'/pozadavky'} activeClassName="border-green-500" className="border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                          Vyřešené
                        </NavLink>
                        
                        
                      </div>
                      {
                        /**
                         * 
                        <div className="hidden lg:flex content-center justify-end items-center ">
                            <svg className="text-gray-300 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                            
                              <form onSubmit={handleSubmit(onSubmit)} className=" inline ">

                                  <input ref={register} onChange={search} onClick={() => {}} className="w-32 px-3 py-3 rounded-lg focus:outline-none border-none focus:border-none focus:ring-0"
                                  type="search" name="filter" autoComplete="off"   placeholder="Vyhledávání" />
                                  
                              </form>
                        </div>  
                         */
                      }
                       
                         

                  </div>
                </div>
                </div>
              </nav>
              {
                        /**
              <div className="flex lg:hidden   items-center px-5">
                    <svg className="text-gray-300 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    
                        <form onSubmit={handleSubmit(onSubmit)} className=" inline ">

                            <input ref={register} onChange={search} onClick={() => {}} className="w-32 px-3 py-3 rounded-lg focus:outline-none border-none focus:border-none focus:ring-0"
                            type="search" name="filter" autoComplete="off"   placeholder="Vyhledávání" />
                            
                        </form>
                </div>  
                 */
              }
              </>
            }
              <div className="px-8 py-5">
                  {props.children}
                  

              </div>
              </div>
        </div>
      </div>
    </div>
        </>
    )
}