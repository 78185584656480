import http from "../http-common";

const upload = (file, uploadId, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("uploadId", uploadId);
  formData.append("module", 'helpdesk');

  return http.post("upload/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = (uploadId) => {
  if(uploadId) {
    return http.get("files/?id=" + uploadId);
  } else {
      return http.get("files/");

  }
};

const deleteFile = (fileId,uploadId) => {
   return http.get("delete/?file_id=" + fileId + "&id=" + uploadId);
};

export default {
  upload,
  getFiles,
  deleteFile
};
 