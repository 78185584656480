import './App.css';
import React, { useState } from "react";
import { useHistory, BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NewItem from "./components/helpdesk/newItem"; 
import ListItems from "./components/helpdesk/list"; 
import HelpdeskDetail from "./components/helpdesk/detail"; 
import Sent from "./components/sent"; 
import Layout from "./components/layout"; 
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useIdleTimer } from 'react-idle-timer';

export default function App() {   
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [building, setBuilding] = useState(JSON.parse(localStorage.getItem('building')));
  const [buildings, setBuildings] = useLocalStorage('buildings');
  const [helpdeskItems, setHelpdeskItems] = useState(building?.helpdesk);    

  function updateItems(data) {
    localStorage.setItem("building", JSON.stringify(data));
    setHelpdeskItems(data.helpdesk);
    setBuilding(data);  
  }
 
  function updateUser(token) {
    if (token) { 
      const jwt = jwtDecode(token);
      const response = {
        accessToken: token,
        id: jwt.id,
        eml: jwt.eml,
        fnm: jwt.fnm,
        snm: jwt.snm,
        img: jwt.img,
        tel: jwt.tel,
        exp: jwt.exp,
      }
      localStorage.setItem("user", JSON.stringify(response));
      
      setUser(response);
    }
  } 

  function logout() { 
    localStorage.removeItem("user");
    localStorage.removeItem("building");
    setBuildings();
    setUser(false);
    setBuilding(false); 
  }
  
  const handleLogin = event => { 
    if (user && Date.now() >= user.exp * 1000) {
      logout();
    }
  } 
  
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 1,
    onIdle: handleLogin,
    onActive: handleLogin,
    onAction: handleLogin,
    debounce: 500
  })

   
  
/*
  function updateBuildings(data) {   
    localStorage.setItem("buildings", JSON.stringify(data)); 
    setBuildings(data);
  }
*/
  async function updateBuilding(building,token) {
     
    if (building) { 
      if(token) {  
        const response = await axios.get('https://system.stephanos.cz/?e=units&unit_id=' + building,
          {
              headers: {
                  "Authorization" : `Bearer ${token}`
              }
          }
        )
        localStorage.setItem("building", JSON.stringify(response.data));
        await setHelpdeskItems(response.data.helpdesk);
        await setBuilding(response.data);  
       
      } else {
        axios.get('https://system.stephanos.cz/?e=units&unit_id=' + building
        )
        .then(response => {
          localStorage.setItem("building", JSON.stringify(response.data));
          setBuilding(response.data);  
        });

      }
    }
  }

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/"  render={(props) => <Layout {...props} component={NewItem}  helpdeskItems={helpdeskItems}  setHelpdeskItems={updateItems} setUser={updateUser} setBuilding={updateBuilding} building={building} buildings={buildings} setBuildings={setBuildings} logout={logout} user={user} />} /> 
          <Route path="/formular-odeslan" render={(props) => <Layout component={Sent} {...props} setUser={updateUser} helpdeskItems={helpdeskItems}  setHelpdeskItems={updateItems}  setBuilding={updateBuilding} building={building} buildings={buildings} setBuildings={setBuildings} logout={logout} user={user}  />} /> 
          <Route path="/objekt/:buildingParam" render={(props) => <Layout {...props} component={NewItem} setUser={updateUser} helpdeskItems={helpdeskItems}  setHelpdeskItems={updateItems} setBuilding={updateBuilding} buildings={buildings} setBuildings={setBuildings} building={building} logout={logout} user={user} />} /> 
          {!building ? <Redirect to="/"/> : null}
          <Route exact path="/pozadavky" render={(props) => <Layout {...props} component={ListItems}  helpdeskItems={helpdeskItems}  setHelpdeskItems={updateItems} setUser={updateUser} setBuilding={updateBuilding} buildings={buildings} setBuildings={setBuildings} building={building} logout={logout} user={user} />} /> 
          <Route exact path="/pozadavky/:typ" render={(props) => <Layout {...props} component={ListItems}  helpdeskItems={helpdeskItems} setHelpdeskItems={updateItems}  setUser={updateUser} setBuilding={updateBuilding} buildings={buildings} setBuildings={setBuildings} building={building} logout={logout} user={user} />} /> 
          <Route path="/pozadavek/:pozadavek" render={(props) => <Layout {...props} component={HelpdeskDetail} helpdeskItems={helpdeskItems}  setHelpdeskItems={updateItems} setUser={updateUser} setBuilding={updateBuilding} buildings={buildings} setBuildings={setBuildings} building={building} logout={logout} user={user} />} /> 
        </Switch>
      </BrowserRouter>
    ) 
};

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) { 
      return initialValue;
    }
  });
  
  const setValue = value => {
    try {
      const valueToStore =
      value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}