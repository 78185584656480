import React, {useEffect} from "react";  
import { NavLink } from "react-router-dom";
import HelpdeskLayout  from "./helpdesk/helpdesk";

export default function Sent(props) { 
  const setTitle = props.setTitle

  useEffect(() => {
    setTitle('Formulář odeslán')
    // eslint-disable-next-line
  }, []);

    return(
        <>
      <HelpdeskLayout {...props}>
  
                <div className="w-full p-8 lg:w-2/3">
                     
                    <div className="pt-8">
                    <div>
                      <h3 className="text-2xl leading-6 my-5 font-medium text-green-500">
                       Požadavek předán správci budovy
                      </h3>
                      <div className="mb-10"><p>Děkujeme za vyplnění formuláře.</p><p>Vyplněné údaje Vám byly odeslány do e-mailové schránky.</p></div>

                      <NavLink to={'/'} className="bg-gray-500 mb-20 inline-block text-white font-bold py-4 px-4   rounded hover:bg-gray-600"
                          
                      >
                          Zpět na formulář
                      </NavLink>

                    </div>
                    
                  </div>
                 </div>  
             
    </HelpdeskLayout>
        </>
    )
}