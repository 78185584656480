import React from 'react';
import {Transition} from "@headlessui/react";

export const Modal = (props) => {
    //const top = useRef(null); 
    const modalIsOpen = props.modalIsOpen
    const setModalIsOpen = props.setModalIsOpen
    const outsideClose = props.outsideClose
    const padding = props.padding 
     
    return (
        <>
            
        <Transition show={modalIsOpen} className="fixed z-50 inset-0  overflow-y-auto">
            <div className="flex flex-wrap h-full items-end  overflow-y-auto   pb-20 pt-4 lg:pb-0 text-center sm:block sm:p-0 sm:pt-10">
             
                <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="absolute inset-0 bg-black opacity-75" onClick={
                        outsideClose ? (
                            () => setModalIsOpen(false)
                        ) : (
                            () => ''
                        )} />
                </Transition.Child>
                <Transition.Child>
               
                </Transition.Child> 
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                             
                <Transition.Child
                    className="inline-block align-bottom  rounded-lg mx-2 text-left overflow-hidden transform transition-all lg:mb-10 sm:align-top sm:max-w-4xl w-full "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                     <div className="absolute top-0 right-2 mx-auto pt-2 z-50" onClick={() => setModalIsOpen(false)}>
                            <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Close sidebar</span>
                            
                                <svg className="h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        
                    <div className={" align-bottom bg-white rounded-lg  text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:w-full  " + (padding && "px-4 pt-14 pb-6 sm:p-6 sm:pt-14 ")}>

                    {props.children}
                        
                    </div>
                </Transition.Child>
            </div>
        </Transition>
        </>
    );
}