import React, { useState, useEffect } from "react"; 
import { useForm } from "react-hook-form";  


export default function BuildingList(props) {
 
  const setModalIsOpen = props.setModalIsOpen;  
  const setBuilding = props.setBuilding; 
  const buildings = props.buildings;
  const setBuildings = props.setBuildings;
  const user = props.user;
 
 // const setBuildings = props.setBuildings; 
  let activeBuilding  
  if(props.building) {
    activeBuilding = props.building.unit; 
  }
  if(!activeBuilding) {
    if(props.buildings) {
      activeBuilding = props.buildings[0];
    }
  }
  
  const listBackup = props.buildings;  
  const [active, setActive] = useState(activeBuilding); 
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {};
  const [tempBuildings, setTempBuildings] = useState();  
  
  useEffect(() => {
    setTempBuildings(buildings);
  }, [buildings]); 
  
  
  function useKeyPress(key,action) {
      useEffect(() => {
        function onKeyup(e) {
          if (e.key === key) action() 
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
      }, [active,buildings]);
    } 
    
    function search(e) { 
      const val = e.target.value;
      let buildingList = listBackup; 
      if(buildingList) {
        buildingList = buildingList.filter((building) => {
          let buildingName = building.units_name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") 
            return buildingName.indexOf(
                val.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1
        })
        
        if(val) {
          setTempBuildings(buildingList) 
          if(buildingList.length > 0) {
            setActive(buildingList[0])
          }
  
        } else {
          setTempBuildings(listBackup)
          setActive(activeBuilding)
        }
      }
      
      
      
    }
    
    function confirmBuilding(alias,user) {
      setBuilding(alias,user);
      setModalIsOpen(false);
    }

      useKeyPress('Enter', () => { 
        confirmBuilding(active.units_alias,user)
      });

      useKeyPress('ArrowUp', () => { 
        let activeKey 
        tempBuildings.map((building, index) =>  {
          if(active.units_id===building.units_id) activeKey = index
        }) 
        activeKey = activeKey-1
        if(activeKey<0) {
          activeKey = 0;
        }
        setActive(tempBuildings[activeKey]);
        
      });

      useKeyPress('ArrowDown', () => { 
       
        let activeKey
        let lastItem
        tempBuildings.map((building, index) =>  {
          if(active.units_id===building.units_id) activeKey = index
          lastItem = index
        }) 
        activeKey = activeKey+1
        if(activeKey>lastItem) {
          activeKey = lastItem;
        }
        setActive(tempBuildings[activeKey]);
         
      });

    return (
      <>
       


    <div className=" flex-col justify-center  ">
        <div  >
          <div className="flex ">
            
                <div className="w-full px-4 lg:px-8 py-5 ">
                    
                     
                    <div className="mt-4 items-center justify-between ">
                       
                          <div className=" flex flew-wrap border-b border-gray-300 mb-5 content-center  pb-2 h-14">
                               
                               <svg className="text-gray-300 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                              </svg>
                                
                                  <form onSubmit={handleSubmit(onSubmit)} className=" inline w-full">

                                      <input ref={register} onChange={search} onClick={() => {}} className="px-3 py-3 w-full rounded-lg focus:outline-none border-none focus:border-none focus:ring-0"
                                      type="search" name="filter" autoComplete="off"   placeholder="Vyhledávání" />
                                      
                                  </form>

                               
                         
                          
                      </div>  
                      
                      {tempBuildings && tempBuildings.map(building => (
                          <button key={building.units_id} onMouseOver={() => setActive(building)} onClick={() => confirmBuilding(building.units_alias,user) }
                              className={"flex justify-between w-full px-4 py-2  text-sm leading-5 text-left outline-none focus:outline-none  my-2 rounded-md hover:bg-green-600 hover:text-white "
                              + (active.units_id===building.units_id ? ("bg-green-600 text-white") : ("bg-gray-100 text-gray-700") )
                            }>
                              <div className="flex items-center px-2 py-2 sm:px-6 w-full">
                                <div className="min-w-0 flex-1 flex items-center">
                                  <div className="flex-shrink-0">
                                    <div className="hidden lg:flex h-12 w-20 overflow-hidden bg-green-200 border-2 text-green-800  rounded-md  flex-wrap content-center justify-center">
                                     {building.units_img ? (

                                       <img className=" object-scale-down w-36" src={"https://system.stephanos.cz" + building.units_img} alt="" />
                                     ) : (
                                      <>  
                                            <svg className="w-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 484.738 484.738" stroke="currentColor"> 
                                            <path d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                                            z"/>
                                          <path d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"/>
                                          <path d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"/>
                                            </svg>  

                                      </>
                                     )
                                     
                                    }
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1 px-2 lg:px-4 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                      <p className="text-base lg:text-xl font-medium">{building.units_name}</p>
                                       
                                    </div>
                                    <div className="hidden md:block">
                                     
                                    </div>
                                  </div>
                                </div>
                                <div>
                                
                                <svg className="h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                              </div>
                            </div>
                               
                          </button>
          
                          
                      ))}
                      
                    </div>
                </div>
            </div>
        </div>
      
          
    </div>
    </>
  );
}