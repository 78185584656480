import React, { useState } from "react";  
import { useForm } from "react-hook-form";
import axios from "axios";  

export default function Finish(props) { 
  const item = props.item;
    
  const [loading, setLoading] = useState(false);     
  const user = props.user;   
  
  const { register, handleSubmit, reset  } = useForm(); 
  
  const token = user.accessToken;
  const config = {
      headers: {
          "Authorization" : `Bearer ` + token
      }
  }  
  const onSubmit = async data => {  
    setLoading(true); 
    const response = await axios.post('https://system.stephanos.cz/api/helpdesk/finish/', {
        helpdesk_id: item.id
    }, config).then(res => { 
  
      props.setHelpdeskItems(res.data);
      props.setModalIsOpen(false);
      reset(response);
      setLoading(false);
    }).catch(err => {       
      setLoading(false);
    })

  };
  
 

    return(
        <>
         
              
        <div className="w-full ">
                   <div className="grid grid-cols-2 border-b border-gray-200 pb-2">

                     <h3 className="text-lg font-medium md:text-2xl   ">
                       {item?.nazev}
                       </h3>
                      
  
                   </div>
               
                      
                       </div>
   
              <div className="pt-6 divide-y divide-gray-200"> 
              <div className="min-w-0 flex-1 mb-10">
                      <div>
                            <form onSubmit={handleSubmit(onSubmit)} >
                            {item && 
                              <input type="hidden" ref={register()} name="item_id" defaultValue={item?.id} />
                            }
                         
                              <div className="relative max-w-xl group"> 
                                <textarea ref={register()} className="border-1 bg-gray-50 border-gray-300 flex-grow  h-24 px-3 py-1 pr-6 w-full rounded-lg focus:border-gray-300 focus:placeholder-gray-200 focus:ring-2 focus:outline-none  focus:ring-green-500"
                                name="message"   placeholder="Napište komentář (nepovinné)"></textarea>
                                
                                 
                              </div>
                              <div className="mt-2">
                                  {loading ? (
                                    
                                    <button disabled className="bg-gray-200 text-gray-800 font-bold py-2 px-10 w-full lg:w-auto rounded cursor-default">
                                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg>
                                      <span>Odesílání...</span></button>
                                  ) : (
                                    <>
                                    <button type="submit" className="bg-gray-700 text-white font-bold py-2 px-10 w-full lg:w-auto rounded hover:bg-gray-600"
                                      
                                    >Uzavřít požadavek</button>
                                    
                                    </>
                                  )}


                              </div>

                          </form>
                      </div> 
                    </div>
              </div> 
 
  
        </>
    )
}