import React, { useState, useEffect } from "react"; 
import { NavLink, useParams } from "react-router-dom"; 
import HelpdeskLayout  from "./helpdesk";
import moment from 'moment'
import 'moment/locale/cs';

export default function ListItems(props) {
  let { typ } = useParams();
  const building = props.building
  const [helpList, setHelpList] = useState(props.helpdeskItems?.todo);
  const [thisOnly, setThisOnly] = useState();

  useEffect(() => {
    if(typ==='aktivni') {
      setHelpList(props.helpdeskItems?.todo);
    } else if(typ==='moje') { 
      setHelpList(props.helpdeskItems?.my);    
    } else {
      setHelpList(props.helpdeskItems?.done);
    }
    // eslint-disable-next-line
  }, [typ, building]);
    

  function filter() { 
    setThisOnly(!thisOnly);
    
    let items = props.helpdeskItems?.my;
    items = items.filter((item) => {
        let unitName = item.alias
        return unitName.indexOf(
          building?.unit?.units_alias) !== -1
    })
    
    thisOnly
    ? setHelpList(props.helpdeskItems?.my)
    : setHelpList(items)
    //let result = cartList.filter(t=>t.id < 120); 
    
  }
 
  const Event = ({item}) => {
      
    const inserted = moment(item.vlozeno).format('D. M. YYYY');
    const done = moment(item.last_update).format('D. M. YYYY');
     
    return(
      <>
      
       
      <NavLink  key={item.id} exact to={'/pozadavek/' + item.number}   className="py-4 flex items-center justify-between hover:bg-gray-50 p-3 rounded-md ">
                          
                       
          <div className="grid grid-cols-3 lg:grid-cols-12 text-sm w-full gap-4">
          <div className="">
              <span className={"font-medium " +  (item.done==="0" && "text-green-600") }># {item.number}</span> 
            </div>
            <div>
            <span className="flex items-start w-full justify-items-start">

              
              <span className="w-6 flex items-start">

              {item.private==='1' ? (
                <svg className="w-4 mr-1 pt-1 inline text-red-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" title="viditelné pouze výboru">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
                ) : ("")}
              {item.private==='2' ? ( 
                <svg className="w-4 mr-1 pt-1 inline text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" title="soukromý požadavek">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
              ) : ("")}
              </span>

              {item.typ}
              </span>
              
            </div>
            
            <div className="col-span-2 " >
              <span className="flex items-start w-full justify-center">
              <svg className="w-4 mr-1 pt-1 inline text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              {inserted}
              </span>
            </div>
            <div className="col-span-4 text-base lg:text-sm order-first lg:order-none">
              <h2 className="  font-medium text-gray-900 ">
               {item.nazev}
              </h2> 

            </div>
            <div className="lg:col-span-2 text-center">
            <span >{item.vlozil}</span>
            </div>
           
           
             
              <div className="col-span-2 justify-end" >
              {typ==='moje' ? (
                <>
                  {item.name}
                </>
              ) : (
                <>
                {item.done==='1' ? (
                  <>
                    <span className="flex items-start w-full justify-end lg:justify-center">
                    <svg className="w-4 mr-1 pt-1 inline text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    {done}
                    </span>
                  </>
                ) : (

                  <div className="bg-gray-100 px-1 py-1 rounded-md flex items-start">
                  <svg  className="w-4 mr-2 pt-1 inline"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                    Vyřizuje {item.prirazeno}

                  </div>

                )
              
                }
                </>
              )} 


              </div>
               
            </div>
             
          </NavLink>
      </>
    )
  } 

    return(
        <>
          <HelpdeskLayout {...props}>
            
                  <div className="w-full ">
                   
                   <h3 className="text-lg text-gray-500 md:text-2xl mx-auto border-b border-gray-200 pb-2">
                     <span className="font-bold">Helpdesk</span> požadavky
                     </h3>
                     </div>

                     {typ==='moje' && building && 
                      <>
                      <div className="flex items-center sm:col-span-4 cursor-pointer my-2" onClick={() => filter()}> 
                          <button  
                          type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                          className={(thisOnly ? 'bg-green-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                          >
                            <span className="sr-only">pouze {building.unit.units_name}</span>
                            <span aria-hidden="true" 
                            className={(thisOnly ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                            </span>
                          </button>
                          <span className="ml-3" id="toggleLabel">
                            <span className={(thisOnly ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm  "}>pouze {building.unit.units_name}</span>
                          </span>
                        </div>
                        </>
                      }
            
                  {helpList?.length === 0 &&
                    <>
                      <ul id="gallery" className="flex flex-1 flex-wrap my-10">
                        <li id="empty" className="h-full w-full text-center flex flex-col justify-center items-center  ">
                          
                              <svg className="w-32 mx-auto text-gray-300" fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
                                viewBox="0 0 512 512" >
                              
                                  <path d="M510.194,221.846L441.735,84.353c-2.922-5.862-8.905-9.567-15.452-9.567H85.717c-6.547,0-12.53,3.705-15.452,9.567
                                    L1.806,221.846C0.616,224.233,0,226.868,0,229.537v190.418c0,9.532,7.726,17.258,17.258,17.258h477.483
                                    c9.532,0,17.258-7.726,17.258-17.258V229.537C512,226.868,511.384,224.233,510.194,221.846z M96.4,109.303h319.195l51.562,103.551
                                    H355.524c-8.158,0-15.216,5.782-16.902,13.761c-0.616,2.917-15.872,71.381-85.498,71.381s-84.883-68.464-85.487-71.312
                                    c-1.628-8.048-8.704-13.83-16.913-13.83H44.843L96.4,109.303z M477.483,402.697H34.517V247.371h103.108
                                    c12.127,35.339,46.644,85.142,115.499,85.142c68.861,0,103.378-49.802,115.499-85.142h108.86V402.697z"/>
                                
                              </svg>

                          

                          <span className="text-small text-gray-400">Žádné položky</span>
                        </li>
                      </ul>
                    </>
                  }
 
            <div className="pt-6 divide-y divide-gray-200"> 
                <div className="mt-2 divide-y divide-gray-200">
                {helpList?.map(item => (
                  <Event key={item.id} item={item} /> 
                  )
                )}
                  
                </div> 
            </div> 
            </HelpdeskLayout>
        </>
    )
}